import m from 'mithril';
import login_comp from '../components/login_comp'
import updatePassword_comp from '../components/updatePassword_comp'

import { 
  getEncData_usersUniqSK, 
  getDecData_usersUniqSK, 
  getEncData_normal, 
  getDecData_normal,
  getUserDistrict,
  getEdunifyAdminPair,
  getQueryParam
} from '../utils/utils'

const getUserEncPair = async(alias, password) => {
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {resolve(null)}, 5000);
    am_district_space.get('pair').get(alias).on(async(data, key, msg, eve) => {
      if(data){
        clearTimeout(timeout);
        eve.off()
        resolve(await getDecData_normal(data, password))
      }
      resolve(data)
    })
  })
}

const getAMAttribute = async(layer, attr, opts) => {
  console.log('GET AM ATTRIBUTE', attr)
  return new Promise((resolve, reject) => {
    const timeout = setTimeout(() => {resolve(null)}, 5000);
    if(layer == '1'){
      am_space.get(attr).on(async(data, key, msg, eve) => {
        if(data){
          clearTimeout(timeout);
          eve.off()
          if(attr == 'ipfs_psk' || attr == 'am_pair'){
            let secret = opts[0]
            resolve(await getDecData_normal(data, secret))
          } else {
            let epub = opts[0]
            let pair = opts[1]
            resolve(await getDecData_usersUniqSK(data, epub, pair))
          }
        }
      })
    } else if (layer == '2'){
      let user_pub = opts[0]
      am_space.get(attr).get(user_pub).on(async(data, key, msg, eve) => {
        if(data){
          clearTimeout(timeout);
          eve.off()
          if(attr == 'auth_req_cert'){
            resolve(data)
          }
          if(attr == 'req_date'){
            let epub = opts[1]
            let pair = opts[2]
            resolve(await getDecData_usersUniqSK(data, epub, pair))
          }
          if(attr == 'group_involved'){
            let secret = opts[1]
            resolve(await getDecData_normal(data, secret))
          }
        }
      })
    }
  })
}

async function parseJwt(token){
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
  return JSON.parse(jsonPayload);
}


async function login(user_email, user_password){
  console.log(`USER SIGN IN ${user_email} ${user_password}`)
  console.log('START TO REQUEST FOR GLOBAL USER INFORMATION')
  // CHECK THE GLOBAL GUN TO GET INFORMATION ASSOCIATED
  let user_info = await getUserDistrict(user_email, user_password)
  console.log(user_info)

  let dm_pubs = {
    wccusd: 'EHWQCfkZBgYMEwGdyOK65AG76KEiZOwL_SGa3pZPdsI.9B8_zuolDlA2jmVCg7nPEB_I8g3OnAPmRBuqnv6AesY'
  }

  // INIT SHORCUT 
  let am_pub = user_info['am_pub']
  let am_epub = user_info['am_epub']
  window.am_space = gun.get(`~${am_pub}`)
  let pair
  console.log('START TO REQUEST FOR ENC_PAIR & AUTH REQ CERT')
  if(user_info['district'] == 'edunify'){
    pair = await getEdunifyAdminPair(user_email, user_password)
    //NEED TO CHOOSE DISTRICT THEN LOGIN
    window.district_name_space = 'wccusd'
  } else {
    window.district_name_space = user_info['district']
    if(district_name_space == 'wccusd'){
      window.am_district_space = am_space.get('EYFL').get('UGRP').get('com_edunity').get('WCCUSD')
    } else {
      window.am_district_space = am_space.get('EYFL').get('UGRP').get('com_edunity').get(district_name_space)
    }
    pair = await getUserEncPair(user_email, user_password)
  }
  let dm_space = gun_lake.get(`~${dm_pubs[district_name_space]}`)

  let user_pub = pair['pub']
  let a_req_cert = await getAMAttribute('2', 'auth_req_cert', [user_pub])

  let req_date = await new Promise((resolve, reject) => {
    dm_space.get('most_recent').on((data, key, msg, eve) => {
      if(data){
        eve.off()
        resolve(data)
      }
    })
  })
  console.log(pair, a_req_cert, req_date)

  document.querySelector('load').classList.remove('hide')
  USER.auth(pair, async(ack) => {
    if (ack.err) {
      alert(ack.err);
    } else {
      console.log(ack)
      let user_pair = ack.sea
      let user_pub = ack.sea.pub
      let user_epub = ack.sea.epub

      let epoch = new Date().getTime()

      let req_obj = JSON.stringify({
        app: 'district'
      })
      let enc_req_obj = await getEncData_usersUniqSK(
        req_obj,
        am_epub, 
        user_pair
      )
      // GENERATE AUTH REQUEST OBJECT 
      let _obj = JSON.stringify({
        dt: epoch,
        epub: user_epub,
        req_obj: enc_req_obj
      })

      // PUT AUTH REQUEST OBJECT INTO UGRP SPACE WITH AUTH REQUEST CERT 
      am_space.get('auth_req').get(user_pub).put(_obj, null, {opt: {cert: a_req_cert}})
      console.log('SESSION REQEST', epoch)

      am_space.get('session').get(user_pub).get(epoch).on(async(data, key) => {
        console.log('^^^^^ SUBSCRIPTION EVENT ON SESSION ^^^^^', key)
        data = typeof data == 'string' ? JSON.parse(data) : data 
        console.log(data)
        console.log('SESSION EPOCH', data['dt'])

        let enc_obj = data['_obj']
        let _obj = await getDecData_usersUniqSK(enc_obj, am_epub, user_pair)
        _obj = typeof _obj == 'string' ? JSON.parse(_obj) : _obj 
        console.log(_obj)

        if(typeof data['e_epoch'] != 'undefined'){
          userSignOut()
          alert('Your session has expired. Please re-signin')
        }

        // THIS ONLY RUN AT SESSION FIRST TIME SIGN IN
        if(data['dt'] == epoch){
          document.cookie = `jwt=${_obj['cookies']}`;
          const tar_domain = `https://app.edunify.com/?district=${district_name_space}&pub=${user_pub}&session=${epoch}#!/main/${req_date}/group/all/-/-`
          https://app.edunify.com/?district=wccusd&pub=V4cYQPLjd6xN99bmzLiwJWU62z9eIpPQrWRqs-R4Vrw.N5QRVCvEV4s_mE2Qjn8TTBVhRpkYIJJeOPtFugEFpw4&session=1724157662379#!/main/20240816/group/all/-/-
          window.location.href = tar_domain;
          // const tar_domain = `https://${district_domain}/?district=${district_name_space}&pub=${user_pub}&session=${epoch}#!/main/${district}/upload/sis/-`
        }
      })
    }
  })
}

async function updatePassword(old_password, new_password) {
  let user_email = decodeURIComponent(getQueryParam('ue'))
  let user_hash = decodeURIComponent(getQueryParam('uh'))
  let enc_user_pair = decodeURIComponent(getQueryParam('epair'))
  let user_pair = await getDecData_normal(enc_user_pair, old_password)
  let new_enc_user_pair = await getEncData_normal(user_pair, new_password)

  let enc_district = decodeURIComponent(getQueryParam('edis'))
  let district = await getDecData_normal(enc_district, old_password)
  let enc_str_am_pair = decodeURIComponent(getQueryParam('apair'))
  let am_pair = await getDecData_normal(enc_str_am_pair, old_password)

  let enc_str_glob_pair = decodeURIComponent(getQueryParam('gpair'))
  let glob_pair = await getDecData_normal(enc_str_glob_pair, old_password)
  if(typeof am_pair == 'undefined' && typeof glob_pair == 'undefined'){
    // document.getElementById('f-old-password').style.borderColor = 'red'
    alert('Please enter correct default password')
    return
  }

  if(new_password == ''){
    alert('Please enter your new password')
    return
  }

  let glob_obj = JSON.stringify({
    district: district,
    am_pub: am_pair['pub'],
    am_epub: am_pair['epub']
  })
  console.log(JSON.parse(glob_obj))
  let enc_glob_obj = await getEncData_normal(glob_obj, new_password)
  await new Promise((resolve, reject) => {
    USER.auth(am_pair, async(ack) => {
      if (ack.err) {
        alert(ack.err);
      } else {
        let user_pub = ack.sea.pub
        let am_district_space
        if(district == 'wccusd'){
          am_district_space = gun.get(`~${user_pub}`).get('EYFL').get('UGRP').get('com_edunity').get('WCCUSD')
        } else {
          am_district_space = gun.get(`~${user_pub}`).get('EYFL').get('UGRP').get('com_edunity').get(district)
        }
        // let am_district_space = am_space.get('EYFL').get('UGRP').get('com_edunity').get(district)
        am_district_space.get('pair').get(user_email).put(new_enc_user_pair, function(ack){
          console.log('ENCRYPT && SAVE PAIR')
          USER.leave()
          resolve('done')
        })
      }
    })
  })

  let rcrd_key = `${user_pair['priv']}_${district}`
  let enc_new_pass = await getEncData_normal(new_password, rcrd_key)

  USER.auth(glob_pair, async(ack) => {
    if (ack.err) {
      alert(ack.err);
    } else {
      let user_pub = ack.sea.pub
      let glob_space = gun.get(`~${user_pub}`)
      glob_space.get('rcrd').get(user_hash).put(enc_new_pass)
      glob_space.get('users').get(user_hash).put(enc_glob_obj, function(ack){
        console.log('ENCRYPT && SAVE DISTRICT')
        const timeout = setTimeout(() => {
          const tar_domain = `https://app.edunify.com`;
          window.location.href = tar_domain;
        }, 3000);
        alert('YOU WILL BE REDIRECT TO LOGIN PAGE IN 3 SECONDS')
      })
    }
  })
}

var loginPage = {
  oncreate: () => {
    document.getElementById('form-login-btn').onclick = () => {
      let name = document.getElementById('f-email').value
      let password = document.getElementById('f-password').value
      login(name, password)
    }
  },
  view: function() {
    return m('div', { class: 'global-access' },  [
      m(login_comp),
      m('div', { class:'footer' }, 
        m('div', { class:'footer-copyright' },
          'Copyright © 2024 DISTRICTOS INC'
        )
      )
    ]);
  }
}

var updatePasswordPage = {
  oncreate: () => {
    document.getElementById('form-update-btn').onclick = () => {
      let old_password = document.getElementById('f-old-password').value
      let new_password = document.getElementById('f-new-password').value
      updatePassword(old_password, new_password)
    }
  },
  view: function() {
    return m('div', { class: 'global-access' },  [
      m(updatePassword_comp),
      m('div', { class:'footer' }, 
        m('div', { class:'footer-copyright' },
          'Copyright © 2024 DISTRICTOS INC'
        )
      )
    ]);
  }
}

// GUN INITIALIZATION
async function initGUN() {
  let is_production = true
  if (is_production) {
    console.log = console.warn = console.error = console.info = function() {};
  }
  window.GUN = require('gun/gun'); // in NodeJS
  window.SEA = require('gun/sea');
  require('gun/lib/radix.js');
  require('gun/lib/radisk.js');
  require('gun/lib/store.js');
  require('gun/lib/rindexed.js');
  require('gun/lib/yson.js');

  window.gun = GUN({
    peers:['https://rl0.auth.edgun.cc:8850/gun'],
    localStorage: false,
    axe: false,
    file: 'c1fadce5eb71'  // SHA256 OF "auth.eyfl.cc"
  });

  window.gun_lake = GUN({
    peers:['https://rl0.lake.edgun.cc:8851/gun'],
    localStorage: false,
    axe: false,
    file: '333dedd7e53c'
  });

  // window.USER = gun.user()
  window.USER = gun.user().recall({sessionStorage: true});
  
  // GUN GLOBAL INITIALIZATION
  const glob_pub = 'OBnU83xveavHOzf7tpZpYIX4oiZYeQwNS7NstjX8SGQ.T-9AYgZjKT7uMMBE8UFDPFedyRvJs8_pY7mBRO17O34'
  window.glob_space = gun.get(`~${glob_pub}`)

  gun.on('in', function(event){
    this.to.next(event)
  })

  gun.on('out', function(event){
    this.to.next(event)
  })

  gun.on('put', function(event){
    this.to.next(event)
  })
}

async function initMITHRIL() {
  console.log('/// initMITHRIL ///');
  const $root = document.querySelector('app');
  window.m = m
  m.route.prefix = '#!';
  m.route($root, '/login', {
    "/login": loginPage,
    "/updatepassword": updatePasswordPage
  });
  document.querySelector('evtg').classList.remove('hide')
}

addEventListener('load', (event) => {
  console.log('The page is fully loaded.');
  initGUN()
    .then((res) => initMITHRIL())
});


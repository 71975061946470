const Hashes = require("jshashes");

export const deepCopy = (data) => {
	return JSON.parse(JSON.stringify(data));
}

export const showLoading = () => {
	document.querySelector('load').classList.remove('hide')
}

export const hideLoading = () => {
	document.querySelector('load').classList.add('hide')
}

export const getUsersUniqSK = async (other_epub, user_pair) => {
  return await SEA.secret(other_epub, user_pair);
};

export const getEncData_usersUniqSK = async (data, other_epub, user_pair) => {
  return await SEA.encrypt(data, await getUsersUniqSK(other_epub, user_pair));
};

export const getDecData_usersUniqSK = async (enc_data, other_epub, user_pair) => {
  return await SEA.decrypt(enc_data, await getUsersUniqSK(other_epub, user_pair));
};

export const getEncData_normal = async (data, secret_key) => {
  return await SEA.encrypt(data, secret_key);
};

export const getDecData_normal = async (encrypted_data, secret_key) => {
  return await SEA.decrypt(encrypted_data, secret_key);
};

export const getUserDistrict = async(alias, password) => {
	let user_hash = new Hashes.SHA256().hex(`districtos_${alias}`)
	console.log(user_hash)
	let obj = await new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {resolve(null)}, 2000);
		glob_space.get('users').get(user_hash).on(async(data, key, msg, eve) => {
			if(data){
				clearTimeout(timeout)
				eve.off()
				resolve(await getDecData_normal(data, password))
			}
		})
	})
	return obj
}

export const getEdunifyAdminPair = async(alias, password) => {
	let obj = await new Promise((resolve, reject) => {
		const timeout = setTimeout(() => {resolve(null)}, 2000);
		glob_space.get('edunify_admin').get(alias).on(async(data, key, msg, eve) => {
			if(data){
				clearTimeout(timeout)
				eve.off()
				resolve(await getDecData_normal(data, password))
			}
		})
	})
	return obj
}

export const getQueryParam = (type) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(type).replaceAll(' ', '+')
}





import { hideLoading, deepCopy, getQueryParam } from '../utils/utils';

import searchIcon from '../icons/search.svg';
import lockIcon from '../icons/lock.svg';
import visibilityIcon from '../icons/visibility.svg';
import edunifyIcon from '../icons/edunify-text-blue.svg';

export default {
	oninit: (vnode) => {
		vnode.state.old_input_type = 'password'
		vnode.state.new_input_type = 'password'

		vnode.state.toggleOldPassword = function() {
			if(vnode.state.old_input_type == 'password'){
				vnode.state.old_input_type = 'text'
			} else {
				vnode.state.old_input_type = 'password'
			}
		}
		vnode.state.toggleNewPassword = function() {
			if(vnode.state.new_input_type == 'password'){
				vnode.state.new_input_type = 'text'
			} else {
				vnode.state.new_input_type = 'password'
			}
		}
	},
	oncreate: async(vnode) => {
		// INITIALIZATION
		let actions = vnode.attrs.actions;
		let dataTableActions = vnode.attrs.dataTableActions;
		hideLoading()
	},
	view: (vnode) => {
		let actions = vnode.attrs.actions;
		return m('div', { class: 'login-cont' }, [
			m('div', { class:'login-info-cont' }, [
				m('img', { src: edunifyIcon }),
				m('h2', 'Welcome to District OS!'),
				m('h5', getQueryParam('ue')),
				m('p', 'Choose a new password to continue'),
			]),
			m('div', { class:'login-input-cont' }, [
				m('div', [
					m('div', { class: 'login-input-outer' }, [
						m('div', { class: 'login-input-field' }, [
							m('label', { for: 'f-old-password' }, 'DEFAULT PASSWORD'),
							m('input', { 
								id: 'f-old-password',
								class: 'form-control',
								type: vnode.state.old_input_type,
								placeholder: ""
							}),
							m('img', { src: lockIcon }),
							m('span', 'Enter your default password'),
						]),
						m('img', { class: 'visibility-icon', src: visibilityIcon, onclick: vnode.state.toggleOldPassword }),
					]),
					m('div', { class: "login-input-outer" }, [
						m('div', { class: 'login-input-field' }, [
							m('label', { for: 'f-password' }, 'NEW PASSWORD'),
							m('input', { 
								id: 'f-new-password',
								class: 'form-control',
								type: vnode.state.new_input_type,
								placeholder: ""
							}),
							m('img', { src: lockIcon }),
							m('span', 'Enter your new password'),
						]),
						m('img', { class: 'visibility-icon', src: visibilityIcon, onclick: vnode.state.toggleNewPassword }),
					]),
					m('p', 'Choose a strong password. Use a combination of letters, numbers and symbols to increase security.'),
				]),
				m('button', { id: 'form-update-btn' }, 'Continue'),
			]),
		])
	},
};
